import {apiClient} from "./api-client.js";

export const getCurrentBusiness = () => apiClient({
    method: 'get',
    url: `/businesses/me`
});

export const updateCurrentBusiness = (values) => apiClient({
    method: 'put',
    url: `/businesses/me`,
    data: values
});
