import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Home',
		redirect: '/dashboard',
		meta: { requiresAuth: true },
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/UnderConstruction.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/calendar',
		name: 'Calendar',
		layout: "dashboard",
		component: () => import('../views/Calendar.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/clienti',
		name: 'Clienti',
		layout: "dashboard",
		component: () => import('../views/Clients.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/client/:id',
		name: 'Client',
		layout: "dashboard",
		component: () => import('../views/Client.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/client-nou',
		name: 'Client nou',
		layout: "dashboard",
		component: () => import('../views/NewClient.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/billing',
		name: 'Billing',
		layout: "dashboard",
		component: () => import('../views/Billing.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/Profile',
		name: 'Profile',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			requiresAuth: true
		},
		component: () => import('../views/UserProfile.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/forgot-password',
		name: 'Forgot password',
		component: () => import('../views/ForgotPassword.vue'),
	},
	{
		path: '/reset-password/:token',
		name: 'Reset password',
		component: () => import('../views/ResetPassword.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;

	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.meta.requiresAuth)) {
		const token = localStorage.getItem('token')
		const currentUser = JSON.parse(localStorage.getItem('currentUser'))
		const isAuthenticated = token && currentUser
		if (isAuthenticated) {
			next()
			return
		}
		next('/login')
	} else {
		next()
	}
})

export default router
