import config from "../config"
import {authClient} from "@/services/auth-client";
import {apiClient} from "@/services/api-client";

export const login = ({email, password}) => authClient({
    method: 'post',
    url: `/auth?access_token=${config.accessToken}`,
    auth: {
        username: email,
        password,
    }
});

export const forgotPassword = (email) => authClient({
    method: 'post',
    url: `/password-resets?access_token=${config.accessToken}`,
    data: {
        email,
        link: `${window.location.origin}/reset-password`,
    }
});

export const checkToken = ({token}) => authClient({
    method: 'get',
    url: `/password-resets/${token}`,
});

export const resetPassword = ({password, token}) => authClient({
    method: 'put',
    url: `/password-resets/${token}`,
    data: {
        password
    }
});

export const updateCurrentUser = (values) => apiClient({
    method: 'put',
    url: '/users/me',
    data: values
})

export const getCurrentUser = () => apiClient({
    method: 'get',
    url: '/users/me',
})



