export const validateResponse = (response, requiredStatus) => {
    if (response.status === requiredStatus) {
        return response.data
    }
    throw new Error('WRONG_STATUS')
}

export const lightOrDark = (color) => {
    let r, g, b, hsp;

    color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;

    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    if (hsp>127.5) {
        return 'light';
    }
    else {
        return 'dark';
    }
}

export const getRanHex = size => {
    let result = [];
    let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

    for (let n = 0; n < size; n++) {
        result.push(hexRef[Math.floor(Math.random() * 16)]);
    }
    return result.join('');
}
