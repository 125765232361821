import axios from "axios";
import config from "../config"

export const apiClient = axios.create({baseURL: config.apiUrl});

apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
})
