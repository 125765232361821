import { defineStore } from 'pinia'
import Vue from "vue";
import {validateResponse} from "@/utils";
import router from "@/router";
import {
    checkToken,
    forgotPassword,
    getCurrentUser,
    login,
    resetPassword,
    updateCurrentUser
} from "@/services/user-service";
import {getCurrentBusiness} from "@/services/business-service";
import axios from 'axios'
import config from "@/config";

export const useUserStore = defineStore('user', {
    state: () => ({
        loading: false,
        tokenIsValid: true,
        currentUser: JSON.parse(localStorage.getItem('currentUser'))
    }),
    actions: {
        async login({email, password}) {
            try {
                this.loading = true

                const userResponse = await login({email, password})
                const currentUser = validateResponse(userResponse, 201)
                this.currentUser = currentUser.user

                localStorage.setItem('currentUser', JSON.stringify(currentUser.user))
                localStorage.setItem('token', currentUser.token)

                this.loading = false
                await router.push('/dashboard').catch(()=>{})
            } catch (e) {
                console.log(e)
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
                this.logout()
            }
        },
        logout() {
            localStorage.clear()
            this.currentUser = {}
            router.push('/login')
        },
        async forgotPassword ({email}) {
            try {
                this.loading = true
                const response = await forgotPassword(email)
                validateResponse(response, 200)
                Vue.$toast.success("Un email cu pasii pentru resetarea parolei a fost trimis.");
                this.loading = false
            } catch (e) {
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async checkToken ({token}) {
            try {
                this.loading = true
                const response = await checkToken({token})
                validateResponse(response, 200)
                this.loading = false
            } catch (e) {
                this.tokenIsValid = false
            }
        },
        async resetPassword ({password, token}) {
            try {
                this.loading = true
                const response = await resetPassword({password, token})
                validateResponse(response, 200)
                Vue.$toast.success("Parola a fost schimbata cu succes!");
                this.loading = false
                router.push('/login')
            } catch (e) {
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async getCurrentUser () {
            try {
                this.loading = true
                const token = localStorage.getItem('token')
                const response = await getCurrentUser()
                const currentUser = validateResponse(response, 200)
                this.currentUser = currentUser
                localStorage.setItem('currentUser', JSON.stringify(currentUser))
                this.loading = false
            } catch (e) {
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async updateCurrentUser (values) {
            try {
                this.loading = true
                const response = await updateCurrentUser(values)
                const currentUser = validateResponse(response, 200)
                this.currentUser = currentUser
                localStorage.setItem('currentUser', JSON.stringify(currentUser))
                Vue.$toast.success("Contul fost modificat cu succes");
                this.loading = false
            } catch (e) {
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
    },
})
